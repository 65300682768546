// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Grommet } from 'grommet'

import Img from 'gatsby-image'

import '@bodhi-project/antrd/lib/just-futura/3.19.3/style/index.css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/style/v2-compatible-reset.css'

import '@bodhi-project/components/lib/containers/default-container.less'
import '@bodhi-project/components/lib/containers/small-default-container.less'
import '@bodhi-project/components/lib/containers/small-container.less'
import '@bodhi-project/components/lib/containers/golden-major-container.less'

import Footer from '@bodhi-project/components/lib/footer-navigations/minimal'
import '@bodhi-project/components/lib/footer-navigations/minimal/style.less'

import LeftRightHeaderNavigation from '@bodhi-project/components/lib/header-navigations/left-right/gatsby'
import '@bodhi-project/components/lib/header-navigations/standard/style.less'
import '@bodhi-project/components/lib/header-navigations/left-right/style.less'
import '@bodhi-project/components/lib/header-navigations/primitives/mobile-layer/style.less'
import '@bodhi-project/components/lib/header-navigations/primitives/list-menu-renderer/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'
import '../../styles/index.less'
import '../../styles/type.less'
import websiteData from '../../data/website.json'
import websiteMenu from '../../data/websiteMenu.json'

import Link from '../Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
export const defaultImage = graphql`
  fragment max90 on File {
    childImageSharp {
      fluid(maxWidth: 90, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max300 on File {
    childImageSharp {
      fluid(
        maxWidth: 300
        quality: 80
        srcSetBreakpoints: [100, 150, 200, 250, 300]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max900 on File {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80, srcSetBreakpoints: [300, 600, 900]) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max1200 on File {
    childImageSharp {
      fluid(
        maxWidth: 1200
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1200]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max1500 on File {
    childImageSharp {
      fluid(
        maxWidth: 1500
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1200, 1500]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  fragment max3000 on File {
    childImageSharp {
      fluid(
        maxWidth: 3000
        quality: 80
        srcSetBreakpoints: [300, 600, 900, 1200, 1500, 2100, 2700, 3000]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

const theme = {
  global: {
    colors: { brand: '#FFD700', text: '#000' },
    edgeSize: {
      small: '8px',
    },
    active: {
      color: '#000',
      background: {
        color: '#FFD700',
      },
    },
    hover: {
      background: '#FFD700',
      text: '#583e85',
    },
  },
  button: {
    color: '#000',
    extend: { fontSize: 'inherit' },
  },
  box: { extend: { overflow: 'hidden' } },
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Layout */
class Layout extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      client: false,
    }
  }

  /** after mount */
  componentDidMount() {
    this.setState({ client: true })
  }

  /** on mount */
  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      if (this.state.client === true) {
        const element = document.getElementById('layout')
        element.scrollTop = 0
      }
    }
  }

  /** standard renderer */
  render() {
    const { children, className = '', data } = this.props
    const classNameX = className

    return (
      <Grommet theme={theme}>
        <div className={classNameX} id="layout">
          <div className="small-default-container">
            <LeftRightHeaderNavigation
              data={websiteMenu}
              Link={Link}
              Img={Img}
              mobileLogo={data.mobileLogo.childImageSharp.fluid}
              desktopLogo={data.desktopLogo.childImageSharp.fluid}
              desktopLogoHeight={56}
            />
          </div>
          <main role="main">{children}</main>
          <div className="small-default-container">
            <Footer
              data={websiteData}
              Link={Link}
              FootNote={
                <Fragment>
                  <p className="text">
                    <span>devoted to high-quality education</span>
                  </p>
                  <p className="year">
                    <span>
                      Reach out to us by{' '}
                      <Link to="mailto:hello@learningwings.org">
                        writing an email
                      </Link>
                      &nbsp;or connect with us on{' '}
                      <Link to="https://www.facebook.com/learningwingseducaitonsystems/">
                        Facebook
                      </Link>
                    </span>
                    <br />
                    <br />
                  </p>
                  <p className="year">
                    Copyright © 2004 — 2020&nbsp;
                    <Link to="https://www.learningwings.org/">
                      Learning Wings Education Systems
                    </Link>
                  </p>
                </Fragment>
              }
            />
          </div>
        </div>
      </Grommet>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// ----------------------------------------------------------------------- Export
export default Layout
